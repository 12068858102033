import React, { useState } from 'react'
import { compact, filter, includes, map } from 'lodash'
import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'

import { useNextQueryParam } from '@docpace/shared-react-hooks/useNextQueryParam'
import { useAdminPracticeBase } from '@docpace/admin-react-hooks'
import { useAdminFavoritesQuery } from '@docpace/admin-react-apollo'
import { PracticeStatus } from '@docpace/shared-ts-types/base/PracticeStatus'
import { useAdminAdminDetailQuery, useAdminBaseQuery, useAdminQueueAthenaPracticeSetUpMutation, useAdminQueueRefreshPracticeAppointmentTypeStatsMutation } from '@docpace/admin-react-apollo'

interface UseAdminBaseProps {
    skip?: boolean
}

export function useAdminBase(props?: UseAdminBaseProps){
    let { skip } = props ?? {}
    const router = useRouter()
    const isAuthPage = router?.pathname?.split('/')?.[1] === 'auth'
    
    const queryPracticeId = useNextQueryParam('practiceId')
    const [fetchError, setFetchError] = useState<ApolloError | null>(null)
    const [queueAthenaPracticeSetup] = useAdminQueueAthenaPracticeSetUpMutation()
    const [queueRefreshPracticeAppointmentTypeStats] =
        useAdminQueueRefreshPracticeAppointmentTypeStatsMutation()
    
    const {
        loading: adminBaseLoading,
        refetch: adminBaseRefetch,
        data: adminBaseData,
    } = useAdminBaseQuery({
        skip: !!fetchError || skip,
        onError: setFetchError,
    })

    const {
        practices: practicesData,
        adminId,
        practiceId: headerPracticeId,
        serverInfo,
    } = adminBaseData || {}
        
    const practiceId = headerPracticeId ?? queryPracticeId
    skip = skip || isAuthPage
    const {
        isLoading: practiceLoading,
        practice,
        providers,
        departments,
        appointmentTypes,
    } = useAdminPracticeBase({ skip, practiceId: practiceId ?? '' })

    const { loading: adminDetailLoading, data: adminData } =
        useAdminAdminDetailQuery({
            variables: { adminId: adminId ?? '' },
            skip: !adminId || !!fetchError || skip,
            onError: setFetchError,
        })
        
    const {
        data: adminFavoritesData,
        loading: adminFavoritesLoading,
    } = useAdminFavoritesQuery({
        variables: { adminId: adminId ?? '' },
        skip: !adminId || !!fetchError || skip,
        onError: setFetchError,
    })

    const {
        adminFavoritePractices,
        adminFavoriteDepartments,
        adminFavoriteProviders,
        adminFavoriteAppointmentTypes,
    } = adminFavoritesData ?? {}

    const favoriteDepartmentIds = map(
        compact(adminFavoriteDepartments?.nodes),
        ({ departmentId }) => departmentId
    )
    const favoriteProviderIds = map(
        compact(adminFavoriteProviders?.nodes),
        ({ providerId }) => providerId
    )
    const favoriteAppointmentTypeIds = map(
        compact(adminFavoriteAppointmentTypes?.nodes),
        ({ appointmentTypeId }) => appointmentTypeId
    )

    const allPractices = compact(practicesData?.nodes)
    const practices = filter(
        allPractices,
        ({ status }) => status !== PracticeStatus.DISABLED
    )
    const disabledPractices = filter(
        allPractices,
        ({ status }) => status === PracticeStatus.DISABLED
    )

    const favoriteDepartments = filter(departments, ({ departmentId }) =>
        includes(favoriteDepartmentIds, departmentId)
    )
    const favoriteProviders = filter(providers, ({ providerId }) =>
        includes(favoriteProviderIds, providerId)
    )
    const favoriteAppointmentTypes = filter(
        appointmentTypes,
        ({ appointmentTypeId }) =>
            includes(favoriteAppointmentTypeIds, appointmentTypeId)
    )

    const favoritePracticeIds = adminFavoritePractices?.nodes?.map(
        ({ practiceId }) => practiceId
    )

    return {
        adminId: adminData?.admin?.adminId ?? null,
        practice: practice ?? null,
        allPractices,
        practices,
        disabledPractices,
        admin: adminData?.admin ?? null,
        actor: adminData?.admin?.adminActor ?? null,
        isProd: !!serverInfo?.isProd,
        webhooksServerUrl: serverInfo?.webhooksServerUrl,
        adminBaseRefetch: ()=>adminBaseRefetch().then(()=>{}),
        queueAthenaPracticeSetup,
        queueRefreshPracticeAppointmentTypeStats,
        favoriteDepartmentIds: map(
            favoriteDepartments,
            ({ departmentId }) => departmentId
        ),
        favoriteProviderIds: map(
            favoriteProviders,
            ({ providerId }) => providerId
        ),
        favoritePracticeIds,
        favoriteAppointmentTypeIds: map(
            favoriteAppointmentTypes,
            ({ appointmentTypeId }) => appointmentTypeId
        ),
        isLoading:
            adminBaseLoading ||
            practiceLoading ||
            adminDetailLoading ||
            adminFavoritesLoading,
    }
}

export default useAdminBase
